<template>
  <div
    class="chart ratio"
    :style="{ height: '100%', paddingBottom: padding + '%' }"
  >
    <div class="chart-wrap">
      <canvas ref="chart"></canvas>

      <div hidden>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    clear: {
      type: [Boolean, String]
    },
    offset: {
      type: Number,
      default: 0
    },
    ratio: {
      type: String,
      default: "16x9"
    },
    labels: {
      type: Array,
      required: true
    },
    legend: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "line"
    }
  },
  data() {
    return {
      sets: [],
      chart: undefined
    };
  },
  computed: {
    padding() {
      let r = this.ratio.split("x");

      return (r[1] / r[0]) * 100;
    }
  },
  watch: {
    labels: function(newVal) {
      this.chart.data.labels = newVal;
      this.chart.update();
    }
  },
  mounted() {
    let _v = this;

    let options = {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: { beginAtZero: true },
            beforeFit(scale) {
              let maxValue = 0;
              if (
                scale.chart.config &&
                scale.chart.config.data &&
                scale.chart.config.data.datasets
              ) {
                scale.chart.config.data.datasets.forEach(dataset => {
                  if (dataset && dataset.data) {
                    dataset.data.forEach(value => {
                      if (value > maxValue) {
                        maxValue = value;
                      }
                    });
                  }
                });
              }

              scale.options.ticks.max = maxValue + _v.offset;
            }
          }
        ],
        xAxes: [{}]
      },
      legend: {
        display: this.legend
      },
      tooltips: {
        mode: "index",
        intersect: false
      },
      hover: {
        mode: "nearest",
        intersect: true
      }
    };

    // if (this.clear === "y") {
    //   options.scales.yAxes[0].display = false;
    //   options.scales.yAxes[0].gridLines = { display: false };
    // } else if (this.clear === "x") {
    //   options.scales.xAxes[0].display = false;
    //   options.scales.xAxes[0].gridLines = { display: false };
    // } else if (this.clear === "grid") {
    //   options.scales.yAxes[0].display = false;
    //   options.scales.yAxes[0].gridLines = { display: false };
    //   options.scales.xAxes[0].gridLines = { display: false };
    // } else if (this.clear) {
    //   options.scales.yAxes[0].display = false;
    //   options.scales.xAxes[0].display = false;
    //   options.scales.yAxes[0].gridLines = { display: false };
    //   options.scales.xAxes[0].gridLines = { display: false };
    // }

    this.chart = new Chart(this.$refs["chart"], {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: this.sets
      },
      options
    });
  }
};
</script>
