var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "chart ratio",
      style: { height: "100%", paddingBottom: _vm.padding + "%" }
    },
    [
      _c("div", { staticClass: "chart-wrap" }, [
        _c("canvas", { ref: "chart" }),
        _vm._v(" "),
        _c("div", { attrs: { hidden: "" } }, [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }